import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import GlobalMeta from '../components/GlobalMeta';
import useConfig from '../hooks/useConfig';

const useElementHeight = (ref) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        const newHeight = ref.current.getBoundingClientRect().height;
        setHeight(newHeight);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return height;
};

const SearchLayout = ({ children }) => {
  const ref = useRef(null);
  const height = useElementHeight(ref);

  const config = useConfig();

  useEffect(() => {
    if (height) {
      window.parent.postMessage(
        {
          msgType: 'childHeightChanged',
          height,
          oldHeight: -1,
        },
        '*',
      );
    }
  }, [height]);

  return (
    <div className="flex min-h-full flex-col bg-background" ref={ref}>
      <GlobalMeta />
      <Head>
        <title>{config.copies.title}</title>
      </Head>
      <div id="top" />
      <main className="mt-2 mb-6 flex-grow max-w-screen-lg mx-auto w-full">{children}</main>
    </div>
  );
};

SearchLayout.propTypes = {
  children: PropTypes.node,
};

SearchLayout.defaultProps = {
  children: null,
};

export default SearchLayout;
