import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import { withStyles } from '@material-ui/styles';

import useConfig from '../hooks/useConfig';
import useGetVehicle from '../hooks/useGetVehicle';
import { phoneRegex } from '../utils/regex';
import createMessageMutation from '../mutations/createMessageMutation';
import createVehicleDescription from '../utils/createVehicleDescription';

export const TopDialog = withStyles({
  container: {
    alignItems: 'flex-start',
  },
  paper: {
    maxHeight: '600px',
  },
})(Dialog);

const SendMessageModal = ({ onClose, visible }) => {
  const [showAlert, setShowAlertAlert] = useState(false);
  const { t } = useTranslation('general');
  const { copies } = useConfig();
  const { vehicle } = useGetVehicle();
  const recaptchaRef = useRef();
  const [loadingrecaptcha, setLoadingRecaptcha] = useState(false);
  const [createMessage, { data, error, loading }] = useMutation(createMessageMutation);

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#top')?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 0);
  }, [visible]);

  const defaultMessage = copies.defaultAsk.replace(
    '{{vehicle}}',
    `${vehicle.brand} ${vehicle.model} ${vehicle.version}`,
  );

  const formik = useFormik({
    initialValues: {
      message: defaultMessage,
      email: '',
      phone: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required Field')),
      message: Yup.string()
        .min(10, t('Too Short'))
        .max(400, t('Too Long'))
        .required(t('Required Field')),
      email: Yup.string().email(t('Invalid Email')).required(t('Required Field')),
      phone: Yup.string()
        .matches(phoneRegex, { message: 'Invalid Phone' })
        .required(t('Required Field')),
    }),
    onSubmit: async (values) => {
      setLoadingRecaptcha(true);
      try {
        const recaptcha = await recaptchaRef.current.executeAsync();
        createMessage({
          variables: {
            email: values.email,
            message: values.message,
            name: values.name,
            phone: values.phone,
            recaptcha,
            slug: window.location.toString(),
            vehicleDescription: createVehicleDescription(vehicle),
            vehicleId: vehicle.id,
          },
        });
        // eslint-disable-next-line no-empty
      } catch {}
      setLoadingRecaptcha(false);
    },
  });

  const closeModal = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (!loading && data) {
      setShowAlertAlert(true);
      closeModal();

      if (window.dataLayer) {
        window.dataLayer.push({ event: 'sent-lead' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  const closeAlert = () => {
    setShowAlertAlert(false);
  };

  return (
    <>
      <TopDialog open={visible} onClose={closeModal} fullWidth maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <div className="flex justify-between">
              <div>{t('details:Ask For Car')}</div>
            </div>
          </DialogTitle>

          <DialogContent>
            <div className="mb-2">
              <TextField
                variant="outlined"
                name="name"
                label={t('Name')}
                className="w-full"
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="sm:flex sm:justify-between sm:-mx-1 sm:pb-1">
              <div className="mb-2 sm:flex-1 sm:m-1">
                <TextField
                  variant="outlined"
                  name="email"
                  label={t('Email')}
                  className="w-full"
                  error={!!formik.errors.email}
                  helperText={formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="mb-2 sm:flex-1 sm:m-1">
                <TextField
                  variant="outlined"
                  name="phone"
                  label={t('Phone')}
                  className="w-full"
                  error={!!formik.errors.phone}
                  helperText={formik.errors.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              name="message"
              className="w-full"
              defaultValue={defaultMessage}
              error={!!formik.errors.message}
              helperText={formik.errors.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ReCAPTCHA
              sitekey="6LeJi1EaAAAAABwzTYSJp4Z-rdNcf8RK5johhyxs"
              size="invisible"
              ref={recaptchaRef}
              hl="es-419"
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} variant="outlined" disabled={loading || loadingrecaptcha}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading || loadingrecaptcha}
            >
              {t('details:Ask')}
            </Button>
          </DialogActions>
        </form>
      </TopDialog>
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert}>
          {Boolean(data) && copies.askAlert}
          {Boolean(error) && t('Error Retry Again')}
        </Alert>
      </Snackbar>
    </>
  );
};

SendMessageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default SendMessageModal;
