import { gql } from '@apollo/client';

const createMessageMutation = gql`
  mutation (
    $email: String!
    $message: String!
    $name: String!
    $phone: String!
    $recaptcha: String!
    $slug: String!
    $vehicleDescription: String!
    $vehicleId: String!
  ) {
    createMessage(
      input: {
        email: $email
        message: $message
        name: $name
        phone: $phone
        recaptcha: $recaptcha
        slug: $slug
        vehicleDescription: $vehicleDescription
        vehicleId: $vehicleId
      }
    ) {
      id
    }
  }
`;

export default createMessageMutation;
