import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import getVehicleQuery from '../queries/getVehicleQuery';

export default function useGetVehicle() {
  const { query = {} } = useRouter();
  const [, id] = query.vehicle || [];

  const { data, error, loading } = useQuery(getVehicleQuery, {
    variables: { vehicleId: id },
  });

  return {
    error,
    loading,
    vehicle: data?.getVehicle,
  };
}
